<template>
  <div>
    <section class="naturally">
      <h1>Speaking Effectively</h1>
      <h3>as an Advanced Speaker of American English</h3>
      <p>This advanced module will expand your ability to speak comfortably, confidently, and naturally when making oral reports, presentations, and engaging in conversations requiring longer continuous speech.</p>
      <p>The recommended method is to record and compare your speech in longer sentences and paragraphs.</p>
      <ol>
          <li>Return to both the "Speaking Clearly" and "Speaking Fluently" modules.</li>
          <li>Select a paragraph. Listen intently, record the entire paragraph. Listen to the entire paragraph again to compare. Pay special attention to your pronunciation and the smoothness of your speech. Are you stressing words correctly? Is your voice rising or lowering to match the native speaker?</li>
          <li>Practice recording every paragraph several times.</li>
          <li>If you are having difficulty with your pronunciation, return to the lessons and exercises in "Speaking Clearly."</li>
          <li>If you are having trouble with the smoothness of your speech, return to "Speaking Fluently."</li>
          <li>When your recordings of these paragraphs match the native speaker, you will have mastered the American accent and achieved the level of an Advanced Speaker of American English.</li>
          <li>TED Talks (5 male and 5 female) are available to practice making 5 minute speeches.
            <div class="button-links">
              <a class="button-link" href="https://youtube.com/playlist?list=PLAkHn0khMM2FpAgiRUnSKdrVaSJDse5Ts&si=Y4pxV26UHGGOtD2q" target="_blank">Male TED Talks</a>
              <a class="button-link" href="https://youtube.com/playlist?list=PLAkHn0khMM2H1EdmJrf4AyTHSwUVjwerv&si=w0LWbudFDfMaaz_Z" target="_blank">Female TED Talks</a>
            </div>
            <p>NOTE: The transcripts for each video can be accessed by clicking on the video description to show it, then click the "Show Transcript" button.  This will show the running transcript.</p>
          </li>
          <li>When you are confidently using the correct pronunciation, stress, rhythm, you are qualified to apply for a certificate as an advanced speaker.</li>
      </ol>
      <button @click="router.push('/')"><i class="fa fa-chevron-left"></i> &nbsp; Back</button>
    </section>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import PageService from '@/services/PageService';
import MiniLoad from "@/components/MiniLoader.vue";

const store = useStore();
const router = useRouter();
</script>

<style lang="scss" scoped>
.naturally {
		background-color: $white;
		padding: 1.5rem 1rem;
    
    h1 {
      margin-bottom: .5rem;
      text-align: center;
    }
    h3 {
      margin-top: 0;
      text-align: center;
    }

    ol {
      padding-left: 1.25rem;
    }

    .button-links {
      display: flex;
      justify-content: space-around;
      margin-left: -1rem;
    }

		button,.button-link {
			background-color: $primary;
			border: 0;
			border-radius: 10px;
			color: $white;
      display: inline-block;
			padding: .5rem .75rem;
      margin-top: .5rem;
      text-decoration: none;
		}

		h4 {
			margin-top: 0;
		}

		li {
			margin-bottom: .5rem;
		}
	}
</style>